// Misc/Utilities

.font-weight-medium {
    font-weight: 500 !important;
}

.text-black-25 {
    color: rgba(#000000, .25);
}

.text-white-25 {
    color: rgba(#ffffff, .25);;
}

.text-success {
    color: #88b72f;
}

.bg-gray-100 {
    background-color: #faf9f9;
}

.bg-gray-200 {
    background-color: $gray-200;
}

.bg-gray-300 {
    background-color: $gray-300;
}

.bg-gray-400 {
    background-color: $gray-400;
}

.bg-gray-500 {
    background-color: $gray-500;
}

.bg-gray-600 {
    background-color: $gray-600;
}

.bg-gray-700 {
    background-color: $gray-700;
}

.bg-gray-800 {
    background-color: $gray-800;
}

.bg-gray-900 {
    background: linear-gradient(225deg, #00B6BA 0%, #0050A8 100%);

    .nav > li > a{
        color: $white;
        text-decoration: none;

        &:hover {
            color: $gray-500;
        }
    }
}

.section-title {
    font-family: 'museo-sans', sans-serif;
    font-weight: 900;
    background:linear-gradient(180deg, #01AFB9 0%, #015BAA 100%);
}

.top-price-box > strong {
    font-size: 1.375rem;
}

.price-box {
    > label {
        font-size: 1.125rem;
        font-weight: 500;
    }

    > p {
        font-size: 2.5rem;
    }
}

.dot {
    display: inline-block;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: #868686;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
}

.dot, .dot-img {
    width: 30px;
    height: 30px;
}
.text-danger-blue{
    color:#0354A4 !important;
}

.steps-container {
    width: 100%;
    position: relative;
    max-width: 605px;

    .connector {
        position: relative;
        top: 46px;
        width: 90%;
        margin: 0 auto;
        border: 1px solid $border-color;
        z-index: -1;
    }

    .step {
        padding: .5em;
        color: $text-muted;

        .number {
            width: 44px;
            height: 44px;
            border: solid 2px $border-color;
            border-radius: 50%;
            text-align: center;
            padding: .08em;
            background-color: $white;

            span {
                border: solid 3px $white;
                border-radius: 50%;
                width: 37px;
                height: 37px;
                display: inline-block;
                padding: .25em;
            }
        }

        &.active {
            color: $body-color;
        }

        &.active > .number {
            color: $white !important;
            border-color: #015baa;
            background: linear-gradient(180deg, #01AFB9 0%, #015BAA 100%);
        }
    }
}
