@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// Colors
$gray-100: #f6f6f6;
$gray-200: #f1f1f1;
$gray-300: #e5e5e5;
$gray-400: #ced4da;
$gray-500: #bfbfbf;
$gray-600: #7a7a7a;
$gray-700: #424242;
$gray-800: #343a40;
$gray-900: #222222;

$primary: #02417e;
$primary-dark: #0354A4;
$info: #0354a4;
$success: #BFE401;
$warning: #f6c037;

$link-color: #0354a4;
$link-hover-color: #2677c7;

$border-width: 2px;
$border-color: $gray-300;

$box-shadow-sm: 0 1px 4px rgba(0, 0, 0, .18);

// Forms

$input-border-color: $gray-600;
$input-btn-line-height: 1.39;
$input-focus-border-color: lighten($info, 25%);
$input-focus-box-shadow: none;
$form-check-input-gutter: 1.5em;

// Body
$body-bg: #ffffff;
$body-color: $gray-900;

// Typography
$font-family-sans-serif: 'Montserrat',
sans-serif;
$font-weight-bold: 600;
$font-weight-bolder: 600;

$headings-font-family: 'museo-sans', sans-serif;
$headings-font-weight: 900;

// Modal
$modal-md: 605px;
$modal-inner-padding: 1.675rem ;
$modal-header-padding-y: 1.675rem;
$modal-header-padding-x: 1.675rem;

// Popovers
$popover-bg: #3ab2e6;
$popover-border-width: 1px;
$popover-body-color: #ffffff;
$popover-box-shadow: none;

