// Borders

.border-top-lg {
    border-top-width: 6px !important;
}

.border-top-xl {
    border-top-width: 10px !important;
}

.border-left-lg {
    border-left-width: 6px !important;
}

.border-primary-dark {
    border-color: $primary-dark !important;
}
.border-danger-blue{
    border-color: #01AFB9 !important;
}